.service-section {
    height: auto;
    margin-top: 100px;
  }
.service-title h3{
    text-align: center;
    color: aqua;

  }

.line{
    display: block;
    width: 70px;
    height: 3px;
    margin: 5px auto;
    background-color: hotpink;
  }
  .services {
    align-items: center;
    border-radius: 10px;
    height: 450px;
    margin-top: 50px;
    color: white;
    background-color: aqua;
    padding: 10px;
    box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
  }
  
  .services:hover {
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
    border-left: 10px solid hotpink;
  }
  
  .service-icon {
    text-align: center;
  }
  .service_name {
    text-align: center;
  }
  .list-of-services .job-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 8px;
    line-height: 1.5;
  }
  
  .list-of-services .bullet-point {
    position: absolute;
    left: 0;
    top: 8px;
    content: '';
    width: 8px;
    height: 8px;
    background-color: hotpink;
    border-radius: 50%;
  }

  .row{
    justify-content: center;
  }


#light .service-title h5{
    margin-top: 100px;
    text-align: center;
    color: orangered;

  }

#light .line{
    display: block;
    width: 70px;
    height: 3px;
    margin: 5px auto;
    background-color: hotpink;
  }
#light .services {
    align-items: center;
    border-radius: 10px;
    height: 450px;
    margin-top: 50px;
    color: white;
    background-color: orangered;
    padding: 10px;
    box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
  }
  
#light .services:hover {
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
    border-left: 10px solid hotpink;
  }
  
#light  .list-of-services .bullet-point {
    position: absolute;
    left: 0;
    top: 8px;
    content: '';
    width: 8px;
    height: 8px;
    background-color: hotpink;
    border-radius: 50%;
  }


  @media (max-width: 1000px) {
    .service-section {
      height: auto;
    }
  
    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .col-xl-3,
    .col-lg-4,
    .col-md-4 {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .services {
      height: auto; /* Set the card height to auto to expand with the content */
    }
  
    .service_name {
      font-size: 18px;
    }
  
    .list-of-services {
      display: block; /* Show the job list by default on mobile */
    }
  }

