.navbar-main{
    background-color: #000000;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    top: 0;
    text-align: center;
    justify-content: right;
    position: sticky;
    z-index: 2;
}

.navbar-scroll-color{
    background: url("../../image/home_background.png") no-repeat center center/cover;
}

.navbar-main ul{
    display: flex;
    margin-right: 10%;    
}

.navbar-main ul li{
    list-style-type: none;
    margin-right: 50px;
    color: aqua;
    cursor: pointer;
    padding-top: 7px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.navbar-main ul li:hover{
    
    color: #ff66c4;
}

.nav-item .active{
    color: #ff66c4;
    line-height: 13px;
}


#light .navbar-main{
    background: url("../../image/home_background.png") no-repeat center center/cover;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    top: 0;
    text-align: center;
    justify-content: right;
    position: sticky;
    z-index: 2;
}


#light .navbar-main ul li{
    list-style-type: none;
    margin-right: 50px;
    color: rgb(232, 38, 38);
    cursor: pointer;
    padding-top: 7px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

#light .navbar-main ul li:hover{
    
    color: #f88f39;
}

#light .nav-item .active{
    color: #f88f39;
    line-height: 13px;
}

@media (max-width: 1000px){
    .navbar-main{
     display: none;
    }
    #light  .navbar-main{
        display: none;
       }
 }