.footer{
    height: auto;
    margin: 0px;
    background-color: aqua;
}
.footer-service h5{
    margin-top: 20px;
    color: hotpink;
    font-family: 'Orbitron', sans-serif;;
}
.footer-about-section h5{
    margin-top: 20px;
    color: hotpink;
    font-family: 'Orbitron', sans-serif;;
}
.footer_social-icons {
    display: flex;
    justify-content: left; 
}

.footer_social-icons p{
    height: 40px;
    width: 40px;
    background-color: white;
    color: white;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px;
}

.footer_social-icons > p:hover {
    background-color: hotpink;
    cursor: pointer;
  }

#light .footer{
    height: auto;
    margin: 0px;
    background-color: #FD8D14;
}

#light .footer-service h5{
    margin-top: 20px;
    color: white;
    font-family: 'Orbitron', sans-serif;;
}
#light .footer-about-section h5{
    margin-top: 20px;
    color: white;
    font-family: 'Orbitron', sans-serif;;
}
.footer_social-icons {
    display: flex;
    justify-content: left; 
}

.footer_social-icons p{
    height: 40px;
    width: 40px;
    background-color: white;
    color: white;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px;
}

.footer_social-icons > p:hover {
    background-color: yellow;
    cursor: pointer;
  }