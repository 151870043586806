.contact-section{
    height: auto;
    margin-top: 100px;
    position: relative;
    margin-bottom: 100px;
}
.image-class img{
    height: 500px;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.contact-form-design{
    height: 700px;
    padding: 30px;
    -webkit-box-shadow: 10px 10px 73px 0px rgba(93,245,235,1);
    -moz-box-shadow: 10px 10px 73px 0px rgba(93,245,235,1);
    box-shadow: 10px 10px 73px 0px rgba(93,245,235,1);
    
}

.text-center h5{
    color: hotpink;
    font-family: 'Orbitron', sans-serif;;
}

.text-center{
    color: white;
}

.contact-form input{
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}
.contact-form textarea{
    height: 250px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.custom-select{
    height: 50px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.button-submit p{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-radius: 30px;
    background-color: aqua;
    color: white;
    padding: 10px;
    width: 50%;
    margin-top: 40px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    cursor: pointer;
}

.button-submit p:hover{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-radius: 30px;
    background-color: hotpink;
    color: white;
    padding: 10px;
    width: 50%;
    margin-top: 40px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    cursor: pointer;
}

.icon-button {
    height: 200px;
    display: flex;
    align-items: center; /* Center icons horizontally */
    justify-content: center;
    background-color: hotpink;
    color: white;
    padding: 100px;
    border-radius: 50%;
    width: 50%;
    margin: 40px auto 0;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  
  .icon-button p {
    margin: 0; /* Remove default margin for <p> elements */
  }
  
  .icon-button a {
    display: inline-flex; /* Use flexbox for inline alignment */
    justify-content: center; /* Center the icon horizontally */
    align-items: center; /* Center the icon vertically */
    width: 40px; /* Set width of each icon */
    height: 40px; /* Set height of each icon */
    border-radius: 50%; /* Create circular shape */
    margin-bottom: 10px; /* Add space between icons */
  }
  
  .icon-button a:hover {
    background-color: white; /* Change background color on hover */
  }
  
  #light .text-center{
    color: rgb(214, 48, 48);
}



/* Styles for smaller screens */
@media (max-width: 1000px) {
    .image-class img {
        display: none; /* Hide the image on smaller screens */
      }
  
    .contact-form-design {
      height: auto;
      padding: 30px;
      box-shadow: 10px 10px 73px 0px rgba(93, 245, 235, 1);
    }
  
    /* Adjust text styles for smaller screens */
    .text-center h5 {
      font-size: 18px;
    }
  
    .text-center {
      color: white;
    }
  
    /* Input and textarea styles for smaller screens */
    .contact-form input,
    .contact-form textarea,
    .custom-select {
      font-size: 14px;
    }
  
    /* Submit button styles for smaller screens */
    .button-submit p {
      width: 100%;
      font-size: 16px;
      margin-top: 20px;
    }
  
    /* Position the image above the contact form */
    .contact-section {
      position: relative;
    }
  
   
  }