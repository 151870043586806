.mobile-view-header{
    width: 100%;
    background: url("../../image/home_background.png") no-repeat center center/cover;
    color: hotpink;
    display: flex;
    align-items: center;

    box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -webkit-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -moz-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
}

.mobile-view-header p svg{
    cursor: pointer;
    color: aqua;
    margin-top: 11px;
    margin-left: 10px;
}

.responsive-mobile-view{
    position: sticky;
    top: 0;
    z-index: 2;
}
.mobile-nav{
    height: auto;
    width: 210px;
    background: url("../../image/home_background.png") no-repeat center center/cover;
    margin-top: 10px;

    top: 0;
    z-index: 2;
}

.mobile-nav ul li{
    color: aqua;
    cursor: pointer;
    margin-top: 10px;
}


#light .mobile-view-header{
    width: 100%;
    background: url("../../image/home_background.png") no-repeat center center/cover;
    color: #f88f39;
    display: flex;
    align-items: center;

    box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -webkit-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -moz-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
}

#light .mobile-view-header p svg{
    cursor: pointer;
    color: #f88f39;
    margin-top: 11px;
    margin-left: 10px;
}

#light .mobile-nav ul li{
    color: #f88f39;
    cursor: pointer;
    margin-top: 10px;
}

@media (min-width: 900px){
    .responsive-mobile-view{
        display: none;
    }
}

@media (max-width: 890px){
    .mobile-nav{
        display: flex;
    }
}

@media (min-width: 900px){
    .responsive-mobile-view{
        display: none;
    }
}

@media (max-width: 890px){
    .mobile-nav{
        display: flex;
    }
}