.home{
    height: 100vh;
    color: aqua;
    
    position: relative;
    z-index: 1;
}

.home:before{
    content: "";
    background: url("../../image/home_background.png") no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.home:after {
    content: "";
    background-color: rgba(50, 30, 30, 0.6); /* Adjust the opacity value as needed */
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .home-content{
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 5px;
  }

  .title {
    color: rgba(255, 102, 196, 0.8);
    z-index: 2;
    font-size: 120px;
    margin-top: 0;
    padding-top: 10px;
    font-family: 'Orbitron', sans-serif;
  }
  .typewriter{
    color: aqua;
    z-index: 2;
    font-size: 40px;
    font-family: 'Orbitron', sans-serif;
  }

  .logo {
    position: absolute;
    z-index: 2;
    top: 100px; /* Adjust the top position as needed */
    left: 170px; /* Adjust the left position as needed */
    width: 160px; /* Set the width of your logo */
    height: auto; /* Maintain aspect ratio */
  }


/* Theme change styles */
.theme-change {
  justify-content: center;
  background: url("../../image/home_background.png") no-repeat center center/cover;
  height: 50px;
  width: 50px;
  right: 0;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  position: fixed;
  top: 150;
  margin-top: 250px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}

#dark .theme-change {
  justify-content: center;
  background: url("../../image/light_background.png") no-repeat center center/cover;
  height: 50px;
  width: 50px;
  right: 0;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  position: fixed;
  top: 150;
  margin-top: 250px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}

@media (max-width: 1000px) {
  .home {
    height: auto;
  }

  .title {
    font-size: 44px;
    text-align: left;
    margin: 0 auto;
  }

  .typewriter{
    color: aqua;
    z-index: 2;
    font-size: 20px;
    font-family: 'Orbitron', sans-serif;
  }
  #light .title {
    font-size: 44px;
    text-align: left;
    margin: 0 auto;
  }
  .theme-change {
    z-index: 2;
  }
  
}

 
 