
.about-section {
    height: auto;
  }

  .about-image{
    margin-top: 100px;
    margin-left: 200px;
  }

  .about-details{
    margin-top: 100px;
    text-align: left;
    padding: 20px;
  }

  .about-title h5{
    text-align: center;
    color: aqua;

  }

  .line{
    display: block;
    width: 70px;
    height: 3px;
    margin: 5px auto;
    background-color: hotpink;
  }

  .about-text{
    color: white;
  }

  .card-design{
    padding-top: 10px;
    background-color: #A076F9;
    border-radius: 10px;
    box-shadow: 9px 6px 7px 3px rgba(255, 1, 233, 0.73);
  }

  .about-list-item{
    display: flex;
  }

  .about-details-icon{
    padding-left: 10px;
    padding-right: 10px;
  }
#light .about-title h5{
  text-align: center;
  color: rgb(255, 187, 0);

}

#light.line{
  display: block;
  width: 70px;
  height: 3px;
  margin: 5px auto;
  background-color: hotpink;
}
#light .about-text{
  color: red;
}
#light .card-design{
  padding-top: 10px;
  background-color: #fa6136;
  border-radius: 10px;
  box-shadow: 9px 6px 7px 3px rgba(228, 144, 49, 0.73);
}
  @media (max-width: 1000px) {
    .about-section {
      height: auto;
    }
  
    .about-image{
      margin-left: 75px;
    }
    .about-image img{
      height: 200px; /* Adjust the height as desired */
      width: 200px; 
      border-radius: 50%;
     
    }
  
    .about-details {
      text-align: center;
      padding: 10px 20px; /* Adjust the padding as desired */
      margin-top: 50px; /* Adjust the margin-top as desired */
    }
  }
  