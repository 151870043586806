.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#light{
  background-color: #FDFFAE;
}


#light .home:before{
  content: "";
  background: url("./image/light_background.png") no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

#light .home-content{
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 5px;
}

#light .title {
  color: rgba(253, 141, 20, 0.8);
  z-index: 2;
  font-size: 120px;
  margin-top: 0;
  padding-top: 10px;
  font-family: 'Orbitron', sans-serif;
}
#light .typewriter{
  color: yellow;
  z-index: 2;
  font-size: 40px;
  font-family: 'Orbitron', sans-serif;
}

#light .logo {
  position: absolute;
  z-index: 2;
  top: 100px; /* Adjust the top position as needed */
  left: 170px; /* Adjust the left position as needed */
  width: 160px; /* Set the width of your logo */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 1000px) {
  
  #light .title {
    color: rgba(253, 141, 20, 0.8);
    z-index: 2;
    font-size: 44px;
    margin-top: 0;
    padding-top: 10px;
    font-family: 'Orbitron', sans-serif;
  }
  #light .typewriter{
    color: yellow;
    z-index: 2;
    font-size: 20px;
    font-family: 'Orbitron', sans-serif;
  }
  
  
}

 
 
